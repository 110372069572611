import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components-tr/seo"
import { StaticImage } from "gatsby-plugin-image"

const description = "Dijital ürün fikrinizi veya mevcut ürünlerinizi konuşmak için tio'yla bir keşif görüşmesi gerçekleştirin."

const BookDemoPage = () => (
  <Layout navbarTheme="dark" className="bg-cream">
    <Seo title="Demo talebi oluştur" description={description} canonical="https://tio.ist/tr/digital-consultancy/" />
 
    <div className="relative py-20">

      <div className="container text-center mx-auto  py-10 lg:px-10 max-w-xs lg:max-w-xl">
      <h1 className="text-5xl  font-bold text-blue">
      Daha iyi bir iş akışı mümkün!
      </h1>
      <p className="text-xl p-10 font-light">
      İşinizi daha iyi hale getirebilmek için neler yapabilceğimizi öğrenmek için sizinle kısa bir görüşme yapabiliriz.
      </p>
      <div className="hidden absolute z-1 -top-10 right-0 lg:block">
          <StaticImage  src="../images/demo-arrow.png"  width={300}  alt="tio" />
        </div>
      <form action="/tr/success" name="demo"  method="post" netlify-honeypot="bot-field" data-netlify="true" >
            <input type="hidden" name="form-name" value="demo" />
            <input className="block bg-white rounded outline-none focus:border-yellow mx-auto p-3 border text-blue my-2 max-w-sm w-full border-blue" required type="text" name="name" placeholder="İsim Soyisim *" />
            <input className="block bg-white rounded outline-none focus:border-yellow mx-auto p-3 border text-blue my-2 max-w-sm w-full border-blue" required type="text" name="name" placeholder="Şirket İsmi *" />
            <input className="block bg-white rounded outline-none focus:border-yellow mx-auto p-3 border text-blue my-2 max-w-sm w-full border-blue" required type="email" name="email" placeholder="E-posta adresi *" />
            <input className="block bg-white rounded outline-none focus:border-yellow mx-auto p-3 border text-blue my-2 max-w-sm w-full border-blue" type="text" name="phone" placeholder="Telefon Numarası" />
                <select className="bg-white max-w-sm w-full border border-blue p-3" name="companySize">
                  <option disabled selected>Şirket Büyüklüğü</option>
                  <option value="1-9">1-9 Çalışan</option>
                  <option value="10-49">1-49 Çalışan</option>
                  <option value="50-249">50-249 Çalışan</option>
                  <option value="250+">250+ Çalışan</option>
                </select>  
            <textarea className="block bg-white rounded outline-none focus:border-yellow mx-auto p-3 border text-blue my-2 max-w-sm w-full border-blue" required rows="5" name="message" placeholder="Neyle ilgileniyorsunuz? Veri analizi, e-ticaret, ürün yönetimi...">       
            </textarea>
            <button className="bg-blue px-5 rounded mt-5 py-2 text-white" type="submit" >Gönder</button>
        </form>
      </div>
    </div>
    


  </Layout>
)

export default BookDemoPage
